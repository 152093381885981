import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { useToast } from '../ui/use-toast';

function RegisterForm() {
  const navigate = useNavigate();
  const { register, isAuthenticated } = useAuth();
  const { toast } = useToast();

  // Form state
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    venmo_handle: '',
  });

  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  // Validate form inputs
  const validateForm = () => {
    const newErrors = {};

    if (formData.username.trim().length < 3) {
      newErrors.username = 'Username must be at least 3 characters long.';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long.';
    }

    if (formData.venmo_handle && !formData.venmo_handle.startsWith('@')) {
      newErrors.venmo_handle = 'Venmo handle must start with @.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Debugging: Log field updates
    console.log(`Updating field: ${name}, Value: ${value}`);

    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validate field on change
    if (errors[name]) {
      const newError =
        name === 'username' && value.trim().length < 3
          ? 'Username must be at least 3 characters long.'
          : name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? 'Please enter a valid email address.'
          : name === 'password' && value.length < 6
          ? 'Password must be at least 6 characters long.'
          : name === 'venmo_handle' && value && !value.startsWith('@')
          ? 'Venmo handle must start with @.'
          : null;

      setErrors((prev) => ({ ...prev, [name]: newError || '' }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setGlobalError('');
    setIsLoading(true);

    // Debugging: Log initial form state
    console.log('Initial FormData:', formData);
    console.log('Field Types:', {
      username: typeof formData.username,
      email: typeof formData.email,
      password: typeof formData.password,
      venmo_handle: typeof formData.venmo_handle,
    });

    if (!validateForm()) {
      toast({
        title: 'Validation Error',
        description: 'Please check your inputs and try again.',
        variant: 'destructive',
      });
      setIsLoading(false);
      return;
    }

    try {
      const { username, email, password, venmo_handle } = formData;

      // Debugging: Log data before the API call
      console.log('Submitting Payload:', { username, email, password, venmo_handle });

      await register(username, email, password, venmo_handle);

      toast({
        title: 'Account Created',
        description: 'Welcome to Poker Debt Solver!',
        variant: 'success',
      });
      navigate('/');
    } catch (error) {
      console.error('Registration Error:', error);

      const errorMessage =
        error.response?.data?.error || 'Failed to create an account. Please try again.';
      setGlobalError(errorMessage);

      toast({
        title: 'Registration Failed',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-900/50">
      <Card className="w-full max-w-md bg-green-800 border-yellow-500 shadow-lg">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center text-yellow-500">Create an Account</CardTitle>
        </CardHeader>
        <CardContent>
          {globalError && (
            <Alert variant="destructive" className="mb-6">
              <AlertDescription>{globalError}</AlertDescription>
            </Alert>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Username Field */}
            <div>
              <label htmlFor="username" className="text-sm font-medium text-yellow-500 block mb-1">
                Username
              </label>
              <Input
                id="username"
                name="username"
                type="text"
                value={formData.username}
                onChange={handleChange}
                placeholder="Choose a username"
                disabled={isLoading}
                required
              />
              {errors.username && <p className="text-xs text-red-500">{errors.username}</p>}
            </div>

            {/* Email Field */}
            <div>
              <label htmlFor="email" className="text-sm font-medium text-yellow-500 block mb-1">
                Email
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                disabled={isLoading}
                required
              />
              {errors.email && <p className="text-xs text-red-500">{errors.email}</p>}
            </div>

            {/* Password Field */}
            <div>
              <label htmlFor="password" className="text-sm font-medium text-yellow-500 block mb-1">
                Password
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Create a password"
                disabled={isLoading}
                required
              />
              {errors.password && <p className="text-xs text-red-500">{errors.password}</p>}
            </div>

            {/* Venmo Handle Field */}
            <div>
              <label
                htmlFor="venmo_handle"
                className="text-sm font-medium text-yellow-500 block mb-1"
              >
                Venmo Handle (Optional)
              </label>
              <Input
                id="venmo_handle"
                name="venmo_handle"
                type="text"
                value={formData.venmo_handle}
                onChange={handleChange}
                placeholder="@your-venmo-username"
                disabled={isLoading}
              />
              {errors.venmo_handle && (
                <p className="text-xs text-red-500">{errors.venmo_handle}</p>
              )}
            </div>

            <Button
              type="submit"
              className="w-full bg-yellow-500 text-black hover:bg-yellow-400"
              disabled={isLoading}
            >
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </Button>
          </form>
        </CardContent>
        <CardFooter>
          <p className="text-sm text-center w-full text-yellow-500">
            Already have an account?{' '}
            <Link to="/login" className="underline hover:text-yellow-400">
              Log in
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

export default RegisterForm;
