// src/components/game/GameDashboard.jsx

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Users, Play, Pause, RotateCcw, User } from "lucide-react";
import { useGame } from "../../contexts/GameContext";
import { useAuth } from "../../contexts/AuthContext";
import { Input } from "../ui/input";
import { motion } from 'framer-motion';
import axiosInstance from '../../utils/axios';
import axios from 'axios'


const GameDashboard = () => {
  const { fetchGame, updateGameStatus } = useGame();
  const { user } = useAuth();
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [game, setGame] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState("60");
  const [customDuration, setCustomDuration] = useState("");
  const [timerInitialized, setTimerInitialized] = useState(false);
  const timerSound = new Audio('/sounds/boxing-bell-1-232450.mp3');
  const [errorMessage, setErrorMessage] = useState("");

  const isCreator = Boolean(game && String(user.id) === String(game.creator_id));

  const durationOptions = [
    { value: "30", label: "30 Minutes" },
    { value: "45", label: "45 Minutes" },
    { value: "60", label: "1 Hour" },
    { value: "90", label: "1.5 Hours" },
    { value: "120", label: "2 Hours" },
    { value: "180", label: "3 Hours" },
    { value: "240", label: "4 Hours" },
    { value: "custom", label: "Custom Duration" },
  ];



  // Fetch game data
  // Fetch game data
  useEffect(() => {
    const fetchGameData = async () => {
      console.log(`Fetching game data for gameId: ${gameId}`);
      try {
        const response = await axiosInstance.get(`/games/${gameId}`, {
          validateStatus: (status) => {
            // Accept only 200 as valid response
            return status === 200;
          },
        });
  
        if (response.status === 200) {
          const data = response.data;
  
          // Parse transactions if needed
          if (data?.gameData?.transactions) {
            if (typeof data.gameData.transactions === 'string') {
              try {
                data.gameData.transactions = JSON.parse(data.gameData.transactions);
              } catch (e) {
                console.error('Error parsing transactions:', e);
                data.gameData.transactions = [];
              }
            }
  
            data.gameData.transactions = Array.isArray(data.gameData.transactions)
              ? data.gameData.transactions.map(transaction =>
                  typeof transaction === 'string' ? { description: transaction } : transaction
                )
              : [];
          }
  
          setGame(data);
          setLoading(false);
  
          if (!timerInitialized && data) {
            setTimeLeft(data.timeLeft || 0);
            setIsRunning(data.status === 'active');
          }
        } else {
          console.error(`Error fetching game data: ${response.status}`);
          setErrorMessage('Failed to load game data. Attempting rejoin...');
          
          // Attempt rejoin
          const rejoinResponse = await axiosInstance.post('/games/rejoin', {
            joinCode: game.join_code, // Ensure join_code is available
            userId: user.id,         // Pass user ID if required
          });
  
          if (rejoinResponse.status === 200) {
            setGame(rejoinResponse.data.game);
            setErrorMessage('');
          } else {
            setErrorMessage('Failed to rejoin the game.');
          }
        }
      } catch (error) {
        console.error('Error in fetchGameData:', error);
        setErrorMessage('Failed to load game data. Please try again later.');
      }
    };
  
    fetchGameData();
  
    // Set up polling interval
    const interval = setInterval(fetchGameData, 5000);
  
    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [gameId, fetchGame, timerInitialized]);


  // Timer logic
  useEffect(() => {
    let interval;

    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prev) => {
          const newTime = Math.max(prev - 1, 0);
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft]);

  // Watch for timeLeft reaching zero
  useEffect(() => {
    if (timeLeft === 0 && isRunning) {
      setIsRunning(false);
      setTimerInitialized(false);
  
      if (isCreator) {
        timerSound.play();
        setTimeout(() => {
          const now = new Date().toISOString();
          updateGameStatus(gameId, { status: "awaiting_balances", game_ended: now })
            .then(() => {
              navigate(`/game/${gameId}/submit-balances`);
            })
            .catch((error) => {
              console.error("Error updating game status:", error);
              setErrorMessage("Failed to update game status. Please try again.");
            });
        }, 5000);
      }
    }
  }, [timeLeft, isRunning, isCreator, navigate, gameId, updateGameStatus]);
  

  const handleStartGame = async () => {
    console.log('Starting game...');
  
    try {
      let durationInMinutes;
      if (selectedDuration === "custom") {
        durationInMinutes = parseInt(customDuration, 10);
        if (isNaN(durationInMinutes) || durationInMinutes <= 0) {
          alert("Please enter a valid custom duration in minutes.");
          return;
        }
      } else {
        durationInMinutes = parseInt(selectedDuration, 10);
      }
  
      console.log(`Selected duration: ${durationInMinutes} minutes`);
  
      await updateGameStatus(gameId, { status: 'active' });
      console.log('Game status updated to "active".');
  
      setGame((prev) => ({ ...prev, status: 'active' }));
      setTimeLeft(durationInMinutes * 60);
      setTimerInitialized(true);
      setIsRunning(true);
  
      console.log(`Timer initialized with timeLeft=${durationInMinutes * 60}`);
    } catch (error) {
      console.error('Error starting game:', error);
      setErrorMessage('Failed to start the game. Please try again.');
    }
  };
  

  const handlePauseResume = () => {
    setIsRunning(!isRunning);
  };

  const handleReset = () => {
    try {
      // Validate selected duration
      let durationInMinutes;
      if (selectedDuration === "custom") {
        durationInMinutes = parseInt(customDuration, 10);
        if (isNaN(durationInMinutes) || durationInMinutes <= 0) {
          alert("Please enter a valid custom duration in minutes.");
          return;
        }
      } else {
        durationInMinutes = parseInt(selectedDuration, 10);
      }

      // Reset timer
      const newTimeLeft = durationInMinutes * 60;
      setTimeLeft(newTimeLeft);
      setIsRunning(true);
    } catch (error) {
      console.error("Error resetting timer:", error);
      setErrorMessage("Failed to reset the timer. Please try again.");
    }
  };

  const handleCompleteGame = async () => {
    try {
      
      await updateGameStatus(gameId, { status: "completed" });
  
     
      setGame((prevGame) => ({
        ...prevGame,
        status: "completed",
      }));
     
      alert("Game has been completed successfully.");
      navigate("/dashboard"); 
    } catch (error) {
      console.error("Error completing game:", error);
      setErrorMessage("There was an error completing the game. Please try again.");
    }
  };

  const getImageUrl = (path) => {
    if (!path) return '';
    const cleanPath = path.replace(/^\/+/, '');
    return `${axiosInstance.defaults.baseURL}/${cleanPath}`;
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const sectionVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  const buttonVariants = {
    hover: { scale: 1.05 },
    tap: { scale: 0.95 },
  };

  const listContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  if (loading) return <div className="text-yellow-500 text-center text-2xl">Loading game...</div>;

  if (errorMessage) return <div className="text-red-500 text-center text-xl">{errorMessage}</div>;
  
  if (!game) return <div className="text-yellow-500 text-center text-2xl">Game data not available.</div>;
  
  return (
    <motion.div
      className="container mx-auto px-4 py-6 sm:py-8 max-w-6xl"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      transition={{ duration: 0.5 }}
    >
      {/* Join Code Section - Made larger */}
      {game.join_code && (
        <motion.div
          className="text-center mb-8 sm:mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-yellow-500 mb-4">
            Game Join Code
          </h1>
          <motion.p
            className="text-4xl sm:text-5xl lg:text-7xl font-mono text-yellow-300 bg-black/20 py-4 sm:py-6 px-6 sm:px-12 border-4 border-yellow-500 rounded-lg inline-block mt-2 break-words"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {game.join_code}
          </motion.p>
        </motion.div>
      )}
  
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
        {/* Left Column - Game Controls or Status Messages */}
        <motion.div
          className="space-y-8"
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Card className="bg-green-900/50 border-yellow-500">
            <CardHeader>
              <CardTitle className="flex items-center gap-3 text-xl sm:text-2xl text-yellow-500">
                <Users className="w-6 h-6 sm:w-8 sm:h-8" />
                {isCreator ? "Game Controls" : "Game Status"}
              </CardTitle>
            </CardHeader>
            <CardContent>
              {isCreator ? (
                <>
                  {game.status === "setup" && (
                    <motion.div
                      className="space-y-4 mb-8"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.3 }}
                    >
                      <label className="block text-lg font-medium text-yellow-500">
                        Game Duration
                      </label>
                      <div className="flex flex-col sm:flex-row gap-4">
                        <Select
                          value={selectedDuration}
                          onValueChange={setSelectedDuration}
                        >
                          <SelectTrigger className="w-full bg-black/20 border-yellow-500 text-yellow-500 text-lg">
                            <SelectValue placeholder="Select preset duration" />
                          </SelectTrigger>
                          <SelectContent className="bg-green-900 border-yellow-500">
                            {durationOptions.map((option) => (
                              <SelectItem
                                key={option.value}
                                value={option.value}
                                className="text-yellow-500 hover:bg-black/20 focus:bg-black/20 focus:text-yellow-500"
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
  
                        {selectedDuration === "custom" && (
                          <div className="flex flex-col sm:flex-row gap-2 items-center">
                            <Input
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              min="1"
                              placeholder="Minutes"
                              value={customDuration}
                              onChange={(e) => setCustomDuration(e.target.value)}
                              className="w-full sm:w-32 bg-black/20 border-yellow-500 text-yellow-500 text-lg"
                            />
                            <span className="text-yellow-500 text-lg sm:ml-2">
                              minutes
                            </span>
                          </div>
                        )}
                      </div>
                    </motion.div>
                  )}
  
                  {/* Timer Display */}
                  <motion.div
                    className="text-center space-y-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                  >
                    <h3 className="text-2xl font-semibold text-yellow-500">
                      Time Remaining
                    </h3>
                    <motion.div
                      className="bg-black/20 rounded-lg p-4 sm:p-8 border-2 border-yellow-500"
                      initial={{ scale: 0.95, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <p className="text-3xl sm:text-5xl font-mono text-yellow-500">
                        {formatTime(timeLeft)}
                      </p>
                    </motion.div>
  
                    <div className="flex gap-4 justify-center">
                      {game.status === "setup" ? (
                        <Button
                          onClick={handleStartGame}
                          className="bg-yellow-500 text-black hover:bg-yellow-400 text-lg py-6 px-8 flex items-center"
                        >
                          <Play className="mr-2 w-6 h-6" />
                          Start Game
                        </Button>
                      ) : (
                        game.status === "active" && (
                          <>
                            <Button
                              onClick={handlePauseResume}
                              className="bg-yellow-500 text-black hover:bg-yellow-400 text-lg py-6 px-8 flex items-center"
                            >
                              {isRunning ? (
                                <>
                                  <Pause className="mr-2 w-6 h-6" />
                                  Pause
                                </>
                              ) : (
                                <>
                                  <Play className="mr-2 w-6 h-6" />
                                  Resume
                                </>
                              )}
                            </Button>
                            <Button
                              onClick={handleReset}
                              className="bg-yellow-500 text-black hover:bg-yellow-400 text-lg py-6 px-8 flex items-center"
                            >
                              <RotateCcw className="mr-2 w-6 h-6" />
                              Reset
                            </Button>
                          </>
                        )
                      )}
                    </div>
                  </motion.div>
                </>
              ) : (
                // Non-Creator Status Messages
                <motion.div
                  className="space-y-6"
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  {game.status === "active" && (
                    <div className="text-center">
                      <h3 className="text-2xl font-semibold text-yellow-500">
                        Game In Progress
                      </h3>
                    </div>
                  )}
  
                  {game.status === "awaiting_balances" && (
                    <div className="text-center">
                      <h3 className="text-2xl font-semibold text-yellow-500">
                        Timer Ended, Count Your Chips
                      </h3>
                    </div>
                  )}
  
                  {game.status === "displaying_transactions" && game.gameData && (
                    <motion.div
                      className="text-center"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                    >
                      <h3 className="text-2xl font-semibold text-yellow-500">
                        Game Results
                      </h3>
  
                      <motion.div
                        className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                      >
                        <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                          Net Results:
                        </h4>
                        <div className="space-y-4">
                          {game.players.map((player) => (
                            <motion.div
                              key={player.id}
                              className="flex flex-col sm:flex-row items-center justify-between p-2 sm:p-4 bg-green-900/50 border border-yellow-500 rounded-lg"
                              variants={listItemVariants}
                              transition={{ duration: 0.3 }}
                            >
                              <div className="flex items-center gap-3 sm:gap-4 w-full">
                                {/* Player Name and Creator Badge */}
                                <span className="text-base sm:text-xl font-semibold text-yellow-500">
                                  {player.name}
                                  {player.user_id === game.creator_id && (
                                    <span className="ml-2 text-sm">(Creator)</span>
                                  )}
                                </span>
                              </div>
                              <div className="mt-2 sm:mt-0 text-right w-full sm:w-auto">
                                <span className="block text-sm sm:text-base text-yellow-500">Buy-In</span>
                                <span className="text-lg sm:text-xl font-mono text-yellow-500">
                                  ${parseFloat(player.buy_in).toFixed(2)}
                                </span>
                              </div>
                            </motion.div>
                          ))}
                        </div>
                      </motion.div>
  
                      <motion.div
                        className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.7 }}
                      >
                        <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                          Transactions:
                        </h4>
                        <ul className="space-y-2">
                          {Array.isArray(game.gameData?.transactions) &&
                          game.gameData.transactions.length > 0 ? (
                            game.gameData.transactions.map((transaction, index) => (
                              <li key={index} className="text-lg text-yellow-500">
                                {typeof transaction === 'string' ? transaction : transaction.description}
                              </li>
                            ))
                          ) : (
                            <li className="text-lg text-yellow-500">
                              {Array.isArray(game.gameData?.transactions)
                                ? "No transactions to display"
                                : "No transactions available"}
                            </li>
                          )}
                        </ul>
                      </motion.div>
                    </motion.div>
                  )}
  
                  {game.status === "completed" && game.gameData && (
                    <motion.div
                      className="text-center"
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.8 }}
                    >
                      <h3 className="text-2xl font-semibold text-yellow-500">
                        Final Game Results
                      </h3>
  
                      <motion.div
                        className="bg-black/20 rounded-lg p-4 sm:p-6 border-2 border-yellow-500"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                      >
                        <h4 className="text-lg sm:text-xl font-semibold text-yellow-500 mb-4">
                          Net Results:
                        </h4>
                        <div className="space-y-4">
                          {game.gameData.players.map((player) => {
                            const net = parseFloat(player.final_balance) - parseFloat(player.buy_in);
                            return (
                              <motion.div
                                key={player.id}
                                className="flex flex-col sm:flex-row justify-between items-center text-base sm:text-lg text-yellow-500"
                                variants={listItemVariants}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: 0.3 }}
                              >
                                <span>{player.name}</span>
                                <span>
                                  Net {net >= 0 ? "Gain" : "Loss"}: $
                                  {Math.abs(net).toFixed(2)}
                                </span>
                              </motion.div>
                            );
                          })}
                        </div>
                      </motion.div>
  
                      <motion.div
                        className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 1.0 }}
                      >
                        <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                          Transactions:
                        </h4>
                        <ul className="space-y-2">
                          {Array.isArray(game.gameData?.transactions) &&
                          game.gameData.transactions.length > 0 ? (
                            game.gameData.transactions.map((transaction, index) => (
                              <li key={index} className="text-lg text-yellow-500">
                                {typeof transaction === 'string' ? transaction : transaction.description}
                              </li>
                            ))
                          ) : (
                            <li className="text-lg text-yellow-500">
                              {Array.isArray(game.gameData?.transactions)
                                ? "No transactions to display"
                                : "No transactions available"}
                            </li>
                          )}
                        </ul>
                      </motion.div>
                    </motion.div>
                  )}
                </motion.div>
              )}
            </CardContent>
          </Card>
        </motion.div>
  
        {/* Right Column - Players Grid */}
        <motion.div
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <Card className="bg-green-900/50 border-yellow-500">
            <CardHeader>
              <CardTitle className="flex items-center gap-3 text-2xl text-yellow-500">
                <Users className="w-8 h-8" />
                Players
              </CardTitle>
            </CardHeader>
            <CardContent>
              {game.players && game.players.length > 0 ? (
                <motion.div
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                  initial="hidden"
                  animate="visible"
                  variants={listContainerVariants}
                >
                  {game.players.map((player) => (
                    <motion.div
                      key={player.id}
                      className="flex flex-col items-center p-4 bg-green-900/50 border border-yellow-500 rounded-lg text-center"
                      variants={listItemVariants}
                      transition={{ duration: 0.3 }}
                    >
                      {/* Profile Picture */}
                      <div className="w-32 h-32 sm:w-24 sm:h-24 rounded-full overflow-hidden border-4 border-yellow-500 mb-4">
                        {player.user?.profile_picture ? (
                          <img
                            src={getImageUrl(player.user.profile_picture)}
                            alt={`${player.name}'s Profile`}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <User className="w-full h-full p-6 text-yellow-500" />
                        )}
                      </div>
  
                      {/* Player Name and Badge */}
                      <div className="text-base sm:text-lg font-semibold text-yellow-500">
                        {player.name}
                        {player.user_id === game.creator_id && (
                          <span className="text-sm block text-yellow-500">
                            (Creator)
                          </span>
                        )}
                      </div>
  
                      {/* Buy-In Amount */}
                      <div className="text-yellow-500 text-sm sm:text-base font-mono mt-2">
                        Buy-In: ${parseFloat(player.buy_in).toFixed(2)}
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              ) : (
                <motion.div
                  className="text-center text-xl text-yellow-500 py-8"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  No players have joined yet.
                </motion.div>
              )}
            </CardContent>
          </Card>
        </motion.div>
  
        {/* Game Results Section - Full Width */}
        {(game.status === "displaying_transactions" || game.status === "completed") &&
          game.gameData &&
          game.gameData.players &&
          game.gameData.transactions && (
            <motion.div
              className="lg:col-span-2 space-y-8"
              initial="hidden"
              animate="visible"
              variants={sectionVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Card className="bg-green-900/50 border-yellow-500">
                <CardHeader>
                  <CardTitle className="text-base sm:text-lg text-yellow-500">
                    {game.status === "completed"
                      ? "Final Game Results"
                      : "Game Results"}
                  </CardTitle>
                </CardHeader>
                <CardContent className="space-y-8">
                  {/* Net Results Section */}
                  <motion.div
                    className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                  >
                    <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                      Net Results:
                    </h4>
                    <div className="space-y-4">
                      {game.gameData.players.map((player) => {
                        const net = parseFloat(player.final_balance) - parseFloat(player.buy_in);
                        return (
                          <motion.div
                            key={player.id}
                            className="flex justify-between items-center text-xl text-yellow-500"
                            variants={listItemVariants}
                            transition={{ duration: 0.3 }}
                          >
                            <span>{player.name}</span>
                            <span>
                              Net {net >= 0 ? "Gain" : "Loss"}: $
                              {Math.abs(net).toFixed(2)}
                            </span>
                          </motion.div>
                        );
                      })}
                    </div>
                  </motion.div>
  
                  {/* Transactions Section */}
                  <motion.div
                    className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                      Transactions:
                    </h4>
                    <ul className="space-y-2">
                      {Array.isArray(game.gameData?.transactions) &&
                      game.gameData.transactions.length > 0 ? (
                        game.gameData.transactions.map((transaction, index) => (
                          <motion.li
                            key={index}
                            className="text-lg text-yellow-500"
                            variants={listItemVariants}
                            transition={{ duration: 0.3 }}
                          >
                            {typeof transaction === 'string' ? transaction : transaction.description}
                          </motion.li>
                        ))
                      ) : (
                        <motion.li
                          className="text-lg text-yellow-500"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.5, delay: 0.7 }}
                        >
                          {Array.isArray(game.gameData?.transactions)
                            ? "No transactions to display"
                            : "No transactions available"}
                        </motion.li>
                      )}
                    </ul>
                  </motion.div>
  
                  {/* Venmo Handles Section */}
                  <motion.div
                    className="bg-black/20 rounded-lg p-6 border-2 border-yellow-500"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.7 }}
                  >
                    <h4 className="text-xl font-semibold text-yellow-500 mb-4">
                      Venmo Handles:
                    </h4>
                    <div className="space-y-2">
                      {game.players.map((player) => (
                        <motion.div
                          key={player.id}
                          className="text-sm sm:text-lg text-yellow-500 py-2 border-b border-yellow-500/30 flex flex-col sm:flex-row items-center justify-between"
                          variants={listItemVariants}
                          transition={{ duration: 0.3 }}
                        >
                          <span>{player.user?.username}</span>
                          {player.user?.venmo_handle ? (
                            <a
                              href={`https://venmo.com/${player.user.venmo_handle.replace('@', '')}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline mt-1 sm:mt-0"
                            >
                              {player.user.venmo_handle}
                            </a>
                          ) : (
                            <span className="ml-2 text-red-500">
                              No Venmo Handle
                            </span>
                          )}
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                </CardContent>
              </Card>
  
              {/* Complete Game Button */}
              {isCreator && game.status === "displaying_transactions" && (
                <motion.div
                  className="text-center mt-4 sm:mt-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                >
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                    transition={{ duration: 0.2 }}
                  >
                    <Button
                      onClick={handleCompleteGame}
                      className="bg-yellow-500 text-black hover:bg-yellow-400 text-lg sm:text-xl py-3 sm:py-4 px-6 sm:px-12"
                      disabled={!game.gameData || !game.gameData.transactions}
                    >
                      Complete Game
                    </Button>
                  </motion.div>
                </motion.div>
              )}
            </motion.div>
          )}
  
      </div>
    </motion.div>
  );
  };
  
  export default GameDashboard;
  