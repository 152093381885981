// src/contexts/AuthContext.jsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axios'; // Ensure axiosInstance is correctly imported
import axios from 'axios'; // For manual requests like refresh

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Logout function
  const logout = async () => {
    try {
      console.log('Attempting to logout...');
      await axiosInstance.post('/auth/logout', {}, { withCredentials: true });
      console.log('Logged out successfully.');
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      delete axiosInstance.defaults.headers.common['Authorization'];
      setUser(null);
      setToken(null);
      console.log('User state cleared.');
    }
  };

  // Initialize authentication on app load
  useEffect(() => {
    const initializeAuth = async () => {
      console.log('Initializing authentication...');
      const storedToken = localStorage.getItem('accessToken');
      const storedUser = JSON.parse(localStorage.getItem('user'));

      console.log('Stored Token:', storedToken);
      console.log('Stored User:', storedUser);

      if (storedToken && storedUser) {
        try {
          // Set the Authorization header
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
          console.log('Authorization header set.');

          // Verify token with backend
          const response = await axiosInstance.get('/auth/verify', {
            headers: { Authorization: `Bearer ${storedToken}` },
          });
          console.log('Token verified successfully:', response.data);
          setUser(response.data.user); // Adjust based on your verify response
          setToken(storedToken);
        } catch (err) {
          console.error('Auth verification failed:', err);
          // Attempt to refresh the token if verification fails
          try {
            console.log('Attempting to refresh token...');
            const refreshResponse = await axios.post(
              '/auth/refresh-token',
              {},
              { withCredentials: true }
            );
            const { accessToken } = refreshResponse.data;
            console.log('Token refreshed successfully:', accessToken);
            localStorage.setItem('accessToken', accessToken);
            setToken(accessToken);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            // Fetch user profile after refreshing token
            const userResponse = await axiosInstance.get('/auth/profile');
            setUser(userResponse.data);
            localStorage.setItem('user', JSON.stringify(userResponse.data));
            console.log('User profile fetched successfully:', userResponse.data);
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            logout();
          }
        }
      } else {
        console.log('No stored token or user found.');
      }

      setLoading(false);
      console.log('Authentication initialization complete.');
    };
    initializeAuth();
  }, []);

  // Login function
  const login = async (email, password) => {
    try {
      setError(null); // Reset errors
      console.log('Attempting to login with email:', email);

      const response = await axiosInstance.post(
        '/auth/login',
        { email, password },
        { withCredentials: true }
      );

      const { accessToken, user: userData } = response.data;
      console.log('Login response:', response.data);

      // Store token and user data
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('user', JSON.stringify(userData));

      // Set Authorization header for future requests
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      console.log('Authorization header set post-login.');

      setUser(userData);
      setToken(accessToken);

      console.log('User logged in successfully:', userData);

      return { success: true };
    } catch (err) {
      console.error('Login error:', err);
      setError(err.response?.data?.error || 'Failed to login');
      return { success: false, error: err.response?.data?.error || 'Failed to login' };
    }
  };

  // Register function
  const register = async (username, email, password, venmoHandle) => {
    console.log('Register payload:', { username, email, password, venmoHandle });
    try {
      setError(null);
      console.log('Attempting to register with email:', email);

      const response = await axiosInstance.post(
        '/auth/register',
        { username, email, password, venmo_handle: venmoHandle },
        { withCredentials: true }
      );

      const { accessToken, user: userData } = response.data;
      console.log('Register response:', response.data);

      // Store token and user data
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('user', JSON.stringify(userData));

      // Set Authorization header for future requests
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      console.log('Authorization header set post-registration.');

      setUser(userData);
      setToken(accessToken);

      console.log('User registered successfully:', userData);

      return { success: true };
    } catch (err) {
      console.error('Registration error:', err);
      console.error('Error response data:', err.response?.data); // Add this line
      setError(err.response?.data?.error || 'Failed to register');
      return { success: false, error: err.response?.data?.error || 'Failed to register' };
    }
  };

  // Update user function
  const updateUser = (updatedUserData) => {
    try {
      setError(null);
      setUser(updatedUserData);
      localStorage.setItem('user', JSON.stringify(updatedUserData));
      console.log('User updated successfully:', updatedUserData);
    } catch (err) {
      console.error('Update user error:', err);
      setError('Failed to update user data locally');
      throw err;
    }
  };

  // Refresh token function (can be called by Axios interceptor if needed)
  const refreshToken = async () => {
    try {
      console.log('Attempting to refresh token...');
      const response = await axios.post(
        '/auth/refresh-token',
        {},
        { withCredentials: true }
      );
      const { accessToken } = response.data;
      console.log('Token refreshed successfully:', accessToken);
      localStorage.setItem('accessToken', accessToken);
      setToken(accessToken);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      return accessToken;
    } catch (err) {
      console.error('Refresh token error:', err);
      logout();
      throw err;
    }
  };

  const value = {
    user,
    token,
    loading,
    error,
    login,
    register,
    logout,
    updateUser,
    isAuthenticated: !!user,
    refreshToken, // Expose refreshToken if needed elsewhere
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
