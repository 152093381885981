import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { calculateStatistics } from '../utils/stats';
import { Trophy } from "lucide-react";
import { useAuth } from '../contexts/AuthContext';
import { useGame } from "../contexts/GameContext";
import axios from '../utils/axios';

// Grouped achievements by category with image references
const categorizedAchievements = {
  "Gameplay Milestones": [
    { id: 1, title: "First Chips Down", description: "Play your first game.", image: "firstchipsdown.png" },
    { id: 2, title: "Casual Competitor", description: "Play 5 games.", image: "casualcompetitor.png" },
    { id: 3, title: "Poker Regular", description: "Play 10 games.", image: "pokerregular.png" },
    { id: 4, title: "Table Resident", description: "Play 20 games.", image: "tableresident.png" },
    { id: 5, title: "Quarterly Grinder", description: "Play 25 games.", image: "quarterlygrinder.png" },
    { id: 6, title: "Shuffling Pro", description: "Play 50 games.", image: "shufflingpro.png" },
    { id: 7, title: "Card Shark", description: "Play 100 games.", image: "cardshark.png" },
    { id: 8, title: "Host with the Most", description: "Create and host 10 games.", image: "hostwiththemost.png" },
    { id: 9, title: "The Invite King", description: "Create and host 5 games.", image: "theinviteking.png" },
  ],
  "Player Interaction": [
    { id: 10, title: "Table Talk Champ", description: "Play 10 games with the same player.", image: "tabletalkchamp.png" },
    { id: 11, title: "Friendly Rival", description: "Play 5 games with the same opponent.", image: "friendlyrival.png" },
    { id: 12, title: "Group Guru", description: "Play with at least 10 different players.", image: "groupguru.png" },
    { id: 13, title: "Diverse Dealer", description: "Play games with 10 different players.", image: "diversedealer.png" },
    { id: 14, title: "Group Loyalty", description: "Participate in a game where everyone has played together before.", image: "grouployalty.png" },
  ],
  "Debt and Balance": [
    { id: 15, title: "Debt Houdini", description: "Escape a game with exactly $0 owed by or to you.", image: "debthoudini.png" },
    { id: 16, title: "The Equalizer", description: "End a game with everyone owing the same amount.", image: "theequalizer.png" },
    { id: 17, title: "Debt Dynamo", description: "Play 10 games where your total debt across all games never exceeds $50.", image: "debtdynamo.png" },
    { id: 18, title: "Profit Machine", description: "Finish 10 games with a positive balance.", image: "profitmachine.png" },
    { id: 19, title: "Debt King", description: "Accumulate more than $500 owed to you across all games.", image: "debtking.png" },
    { id: 20, title: "Debt Mountain", description: "Accumulate more than $500 owed by you across all games.", image: "debtmountain.png" },
  ],
  "Streak Achievements": [
    { id: 21, title: "Lucky Streak", description: "Win 5 games in a row.", image: "luckystreak.png" },
    { id: 22, title: "Victory Lap", description: "Win 3 games in a row.", image: "victorylap.png" },
    { id: 23, title: "Cold Streak Survivor", description: "Lose 3 games in a row but come back to win the next one.", image: "coldstreaksurvivor.png" },
    { id: 24, title: "Unlucky Streak", description: "Lose 5 games in a row.", image: "unluckystreak.png" },
  ],
  "Special Circumstances": [
    { id: 25, title: "Weekend Warrior", description: "Play 3 games in one weekend.", image: "weekendwarrior.png" },
    { id: 26, title: "Late-Night Gambler", description: "Play a game that starts after midnight.", image: "latenightgambler.png" },
    { id: 27, title: "The Glue", description: "Be in a game where every other player owes you or you owe them.", image: "theglue.png" },
    { id: 28, title: "Even Steven", description: "End 3 games with the same exact balance as your buy-in.", image: "evensteven.png" },
    { id: 29, title: "Perfectly Balanced", description: "Have every player in a game owe exactly the same amount.", image: "perfectlybalanced.png" },
    { id: 30, title: "Consistently Mediocre", description: "Finish middle-of-the-pack in 10 games.", image: "consistentlymediocore.png" },
    { id: 34, title: "Master of Margins", description: "Win a game by less than $1.", image: "masterofmargins.png" },
  ],
};

const AchievementsPage = () => {
  const { user, token, isAuthenticated, loading: authLoading } = useAuth();
  const { userGames: games, fetchUserGames } = useGame();
  const [earnedAchievements, setEarnedAchievements] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('useEffect triggered:', { authLoading, user, token });
    if (!authLoading && isAuthenticated && token) {
      fetchAchievementsAndStats();
    } else if (!authLoading) {
      console.log('Auth loading is false but user or token is missing');
      setLoading(false); // Ensure loading is false to stop the spinner
    }
  }, [authLoading, isAuthenticated, user, token]);
  
  const fetchAchievementsAndStats = async () => {
    console.log("Starting fetchAchievementsAndStats...");
    try {
      if (!user || !token) {
        console.log("No user logged in or token is missing, exiting fetchAchievementsAndStats.");
        setLoading(false);
        return;
      }

      // Fetch user games for stats calculation
      console.log("Fetching user games for stats...");
      const userGames = await fetchUserGames();

      if (!userGames || !Array.isArray(userGames)) {
        console.warn("No user games data received or data is not an array.");
        setStats(null);
        return;
      }

      // Filter games where the user is a player
      const filteredGames = userGames.filter((game) =>
        game.players.some((player) => player.user_id === user.id)
      );

      console.log("Filtered user games for stats:", filteredGames);

      // Calculate stats for the user
      const calculatedStats = calculateStatistics(filteredGames, user.id);
      console.log("Calculated stats:", calculatedStats);

      // Set stats, including total completed games
      setStats({
        totalCompletedGames: calculatedStats.totalCompletedGames,
        biggestWin: calculatedStats.biggestWin,
        netProfit: calculatedStats.netProfit,
        bestGameDate: calculatedStats.bestGameDate,
      });

      // Fetch achievements from the backend
      console.log("Fetching achievements...");
      
      const { data } = await axios.get(`/achievements`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use token from context
        },
      });

      console.log("Fetched achievements data:", data);

      // Validate data structure
      if (!data || !data.achievements) {
        console.warn("No achievements data received");
        return;
      }

      // Update state with earned achievements
      setEarnedAchievements(
        data.achievements
          .filter((achievement) => achievement.earned)
          .map((achievement) => achievement.id)
      );
    } catch (error) {
      console.error("Error fetching achievements and stats:", error);
    } finally {
      setLoading(false);
      console.log("fetchAchievementsAndStats completed");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-poker-green">
        <div className="text-center text-xl text-poker-gold">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-poker-green p-4">
      <div className="max-w-5xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Trophy className="w-8 h-8 text-poker-gold" />
          <h1 className="text-3xl font-bold text-poker-gold">Achievements</h1>
        </div>

        {/* Stats Section */}
        {stats && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <Card>
              <CardHeader>
                <CardTitle>Total Completed Games</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-xl">{stats.totalCompletedGames}</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Net Profit</CardTitle>
              </CardHeader>
              <CardContent>
              <p className="text-xl">{stats.netProfit}</p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Biggest Win</CardTitle>
              </CardHeader>
              <CardContent>
              <p className="text-xl">{stats.biggestWin}</p>
                {stats.bestGameDate && (
                  <p className="text-sm text-gray-500">
                    on {new Date(stats.bestGameDate).toLocaleDateString()}
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        )}

        {/* Achievements Section */}
        {Object.entries(categorizedAchievements).map(([category, achievements]) => (
          <div key={category} className="mb-8">
            <h2 className="text-2xl font-bold text-poker-gold mb-4">{category}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {achievements.map((ach) => (
                <Card
                  key={ach.id}
                  className={`${
                    earnedAchievements.includes(ach.id)
                      ? ""
                      : "opacity-50 grayscale"
                  }`}
                >
                  <CardHeader className="flex items-center justify-center">
                    <img
                      src={`/assets/achievements/${ach.image}`}
                      alt={ach.title}
                      className="w-32 h-32 rounded-full border-4 border-poker-gold shadow-lg"
                    />
                  </CardHeader>
                  <CardContent>
                    <p
                      className={`text-center text-xl ${
                        earnedAchievements.includes(ach.id)
                          ? "text-poker-gold"
                          : "text-gray-400"
                      } font-semibold`}
                    >
                      {ach.title}
                    </p>
                    <p
                      className={`text-center text-sm ${
                        earnedAchievements.includes(ach.id)
                          ? "text-poker-gold/80"
                          : "text-gray-400"
                      }`}
                    >
                      {ach.description}
                    </p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AchievementsPage;
